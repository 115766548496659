import { CSSProperties } from 'react';
import { ThemeProps } from '../@types/theme-props';
import themeStyles from './theme-styles';
import { TinyColor } from '@ctrl/tinycolor';
import nspColorAsString from './nsp-color-as-string';
import equalThemeBackground from './equal-theme-background';

/**
 * A class which provides the current theme for a tree of components, as well as
 * helpful properties for understanding the coloring of the theme.
 */
export default class CurrentTheme {
  /** The details of the current theme, usually coming from NSP. */
  readonly theme: ThemeProps;
  /** The details of the previous theme as calculated by `<ThemedComponents>`. */
  readonly prevTheme?: ThemeProps;

  constructor(theme?: ThemeProps, prevTheme?: ThemeProps) {
    this.theme = theme || {};
    this.prevTheme = prevTheme;
  }

  get backgroundColor() {
    return nspColorAsString(this.theme.backgroundColor);
  }

  get textColor() {
    return nspColorAsString(this.theme.textColor);
  }

  /** The CSS styles that the theme applies. */
  get themeStyles(): CSSProperties {
    return themeStyles(this.theme);
  }

  /** True if the current theme's background is considered dark. */
  get isDarkBackground(): boolean {
    return !!this.backgroundColor && new TinyColor(this.backgroundColor).isDark();
  }

  /**
   * Whether the theme background is the same as the previous theme rendered on the page.
   * This is mostly useful for themes rendered within top-level containers via the
   * `<ThemedComponents>` wrapper. If a section has the same background color
   * as the previous one, it typically needs to remove its top padding so that the gap
   * between the two sections doesn't look too big.
   */
  get sameBackgroundAsPrevious(): boolean {
    return !!this.prevTheme && equalThemeBackground(this.theme, this.prevTheme);
  }
}
