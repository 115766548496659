import { isEmpty, omitBy } from '@s-libs/micro-dash';
import type { CSSProperties } from 'react';
import { ThemeProps } from '../@types/theme-props';
import { ThemeColor } from '../@types/theme-color';
import nspColorAsString from './nsp-color-as-string';
import { TinyColor } from '@ctrl/tinycolor';

/**
 * Given a color, returns the contrasting either dark or light color.
 * Useful for deciding what foreground text to match against a background.
 */
function contrastColor(
  color: ThemeColor,
  darkColor?: ThemeColor,
  lightColor?: ThemeColor
): ThemeColor {
  const hexColor = nspColorAsString(color);
  if (!hexColor) return;
  return new TinyColor(hexColor).isDark() ? lightColor || '#ffffff' : darkColor || '#000000';
}

/**
 * Returns an object of CSS properties extracted from the theme, which can then be applied to
 * an element's `style` prop in order to cascade the current theme to Tailwind classes.
 */
export default function themeStyles(theme: ThemeProps): CSSProperties {
  if (!theme) return {};
  return omitBy(
    {
      ['--background-color' as any]: nspColorAsString(theme.backgroundColor),
      ['--text-color' as any]: nspColorAsString(theme.textColor),
      ['--link-color' as any]: nspColorAsString(theme.linkColor),
      ['--primary-color' as any]: nspColorAsString(theme.primaryColor),
      ['--secondary-color' as any]: nspColorAsString(theme.secondaryColor),
      ['--primary-contrast-color' as any]: contrastColor(
        theme.primaryColor,
        theme.darkColor,
        theme.lightColor
      ),
      ['--secondary-contrast-color' as any]: contrastColor(
        theme.secondaryColor,
        theme.darkColor,
        theme.lightColor
      ),
    },
    isEmpty
  );
}
