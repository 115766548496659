import { createContext } from 'react';
import { ThemeProps } from '../@types/theme-props';
import CurrentTheme from './current-theme';

export interface ThemeContextProps {
  changeTheme: (newTheme: ThemeProps | null) => void;
  theme: CurrentTheme;
}

/**
 * A context which allows child components to understand the current theme and render
 * styles accordingly.
 */
const ThemeContext = createContext<ThemeContextProps>({
  theme: new CurrentTheme(),
  changeTheme: function changeTheme() {},
});

export default ThemeContext;
