import { TinyColor } from '@ctrl/tinycolor';

/**
 * Returns true if all colors given are considered equivalent. In this case #fff, #ffffff, white
 * and transparent are all considered equivalent, but #f0f0f0 is not equivalent to #fff.
 */
export default function equalColor(...colors: string[]) {
  const transformedColors = colors.map((color) =>
    !color || color === 'transparent' ? '#ffffff' : new TinyColor(color).toHexString()
  );
  return transformedColors.every((val) => val === transformedColors[0]);
}
