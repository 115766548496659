// disable auto-story
'use client';

import ComponentGroup from '@nrdy-marketing-engine/components/src/component-group';
import { EventEmitter, EventEmitterContext } from '@nrdy-marketing-engine/eventing';
import { FormContext } from '@nrdy-marketing-engine/form-context';
import { useNspContext } from '@nrdy-marketing-engine/nsp/src/nsp-context';
import { Spinner } from '@nrdy-marketing-engine/spinner';
import type { ThemeProps } from '@nrdy-marketing-engine/theming/@types/theme-props';
import useThemeContext from '@nrdy-marketing-engine/theming/src/use-theme-context';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

const formTheme = { backgroundColor: '#f9f9f9' };

enum State {
  /** Shows the main content (not the form) */
  ShowContent,
  /** Fades out the main content to show just the background */
  HidingContent,
  /** Loads the form component asynchronously */
  LoadingForm,
  /** Fades in and slides to show the form component */
  ShowingForm,
  /** Shows the form component */
  ShowForm,
  /** Fades in and slides to show the main content */
  ShowingContent,
}

/* istanbul ignore next */
/**
 * Asynchronously loads the FormWizard on the client-side when we need to display it,
 * in order to reduce the initial page footprint.
 */
const DynamicForm = dynamic<{ initialData: []; steps: [] }>(
  () => import('@nrdy-marketing-engine/form-wizard').then((mod) => mod.Form),
  { ssr: false }
);

interface SectionWrapperProps {
  children: ReactNode;
  height?: number;
  hideContent?: boolean;
  showSpinner?: boolean;
}

/**
 * A wrapper for this section which is used to transition the opacity, height and color
 * between the content and the form.
 */
function SectionWrapper({ children, height, hideContent, showSpinner }: SectionWrapperProps) {
  const styles = { height: height ? `${height}px` : 'initial' };

  // classes for the outer div which handles sliding up and down
  const mainClassName = classNames('relative transition-[height]', {
    ['overflow-hidden']: height,
  });

  // classes for the inner div which handles opacity of content
  const contentClassName = classNames(
    'transition-opacity duration-300',
    hideContent ? 'opacity-0' : 'opacity-100',
    height ? 'absolute w-full' : ''
  );

  // classes for the spinner SVG, making it conditionally fade in
  const spinnerClassName = classNames(
    'mx-auto absolute left-1/2 top-1/2 -ml-3 -mt-3 transition-opacity duration-300',
    showSpinner ? 'opacity-100' : 'opacity-0'
  );

  return (
    <div className={mainClassName} style={styles}>
      <div className={contentClassName}>{children}</div>
      {!!height && <Spinner className={spinnerClassName} />}
    </div>
  );
}

interface NspContainerProviderProps {
  children: ReactElement;
  spaced?: boolean;
  sticky?: boolean;
  theme?: ThemeProps;
}

/**
 * A wrapper around a container component, which can listen for "get-started" events and
 * transition to dynamically replace the section with a lead form.
 */
function NspContainerProvider({ children, spaced }: NspContainerProviderProps) {
  const { changeTheme } = useThemeContext();
  const { leadForm } = useNspContext();

  const [eventEmitter] = useState(new EventEmitter());
  const [state, setState] = useState(State.ShowContent);
  const [containerHeight, setContainerHeight] = useState(0);
  const [formImported, setFormImported] = useState(false);

  // this ref points to the wrapper div for the content and form, which we use to measure
  // its height and resize the SectionWrapper accordingly
  const ref = useRef<HTMLDivElement>();

  // Whenever the DOM updates because of state change, calculate the current
  // container height, which we then use for the slide up-down
  useEffect(() => {
    let timer;

    // When we are showing or about to show a change of content, then capture its height.
    // We need to wait 1 tick for this because in the case of dynamic form, it can be loaded
    // and run this effect before the DOM actually gets rendered
    if (
      [State.ShowContent, State.ShowingContent, State.ShowForm, State.ShowingForm].includes(state)
    ) {
      timer = setTimeout(() => setContainerHeight(ref.current.getBoundingClientRect().height), 1);
    }

    return () => clearTimeout(timer);
  }, [state]);

  // this effect relies on eventEmitter AND themeContext so we make it a separate effect,
  // it is just to update the ThemeContext whenever the user closes the form,
  // to remove the overridden theme
  useEffect(() => {
    const getStartedSub = eventEmitter.subscribe('OPEN_FORM', () => {});
    const closeFormSub = eventEmitter.subscribe('CLOSE_FORM', () => {});
    return () => {
      getStartedSub.unsubscribe();
      closeFormSub.unsubscribe();
    };
  }, [eventEmitter, changeTheme]);

  // on startup, subscribe to events emitted from child controls
  // and transition the state accordingly
  useEffect(() => {
    // subscribe to 'get-started' buttons and anything else which triggers a form
    const getStartedSub = eventEmitter.subscribe('OPEN_FORM', () => {
      setState(State.HidingContent);
      setTimeout(() => {
        setState(State.LoadingForm);
      }, 300);

      // dynamically import the form JS
      import('@nrdy-marketing-engine/form-wizard').then(() => setFormImported(true));
    });

    // subscribe to user clicking 'back' to close the form
    const closeFormSub = eventEmitter.subscribe('CLOSE_FORM', () => {
      changeTheme(null);
      setState(State.ShowingContent);
      setTimeout(() => setState(State.ShowContent), 300);
    });

    // when component reloads or unloads, unsubscribe from events and clear timeouts
    return () => {
      getStartedSub.unsubscribe();
      closeFormSub.unsubscribe();
    };
  }, [eventEmitter, changeTheme]);

  // this effect gets recalled every time that the state changed OR the form gets imported
  // so we can use it to transition from LoadingForm -> ShowingForm -> ShowForm
  useEffect(() => {
    if (formImported && state === State.LoadingForm) {
      changeTheme(formTheme);
      setState(State.ShowingForm);
      const topNavbarHeight = document.querySelector('header')?.getBoundingClientRect().height || 0;
      const bannerHeight = document.querySelector('.sticky')?.getBoundingClientRect().height || 0;

      const newWindowTop =
        ref.current?.getBoundingClientRect()?.top + window.scrollY - topNavbarHeight - bannerHeight;

      window.scrollTo({
        top: newWindowTop,
      });

      setTimeout(() => setState(State.ShowForm), 300);
    }
  }, [formImported, state, changeTheme]);

  // set up variables for renderering so we understand what we are supposed to
  // do during different states

  /** During these phases, the form and content should be faded out */
  const hideContent = [State.HidingContent, State.LoadingForm].includes(state);

  /** During these states, the form should be rendered instead of the content */
  const showForm = [State.ShowForm, State.ShowingForm, State.LoadingForm].includes(state);

  /** During these states, we should be sliding the height */
  const transitionHeight = [
    State.HidingContent,
    State.LoadingForm,
    State.ShowingForm,
    State.ShowingContent,
  ].includes(state);

  return (
    <EventEmitterContext.Provider value={eventEmitter}>
      <SectionWrapper
        height={transitionHeight ? containerHeight : null}
        hideContent={hideContent}
        showSpinner={state === State.LoadingForm}
      >
        <div ref={ref}>
          {showForm ? (
            <FormContext.Provider value={leadForm}>
              <DynamicForm initialData={leadForm?.hiddenFields} steps={leadForm?.steps} />
            </FormContext.Provider>
          ) : (
            <ComponentGroup spaced={spaced}>{children}</ComponentGroup>
          )}
        </div>
      </SectionWrapper>
    </EventEmitterContext.Provider>
  );
}

export default NspContainerProvider;
