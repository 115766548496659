'use client';
// TODO: Remove themeContext to make this a server component, and pass down theme information
// directly from the container component.

import type { ThemeProps } from '@nrdy-marketing-engine/theming/@types/theme-props';
import useThemeContext from '@nrdy-marketing-engine/theming/src/use-theme-context';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ComponentGroupProps {
  children: ReactNode;
  className?: string;
  spaced?: boolean;
  theme?: ThemeProps;
}

/**
 * A wrapper component that provides padding and theming for components inside, typically used to
 * separate the main sections of a page.
 */
function ComponentGroup({ className, children, spaced = true }: ComponentGroupProps) {
  const themeContext = useThemeContext();
  const classes = twMerge(
    'transition-[padding-top]',
    spaced && 'py-8 md:py-12 xl:py-18',
    spaced && themeContext.theme.sameBackgroundAsPrevious && 'pt-0 md:pt-0 xl:pt-0',
    className
  );

  return <div className={classes}>{children}</div>;
}

export default ComponentGroup;
