import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { ThemeProps } from '../@types/theme-props';
import CurrentTheme from './current-theme';
import { ThemeContextProps } from './theme-context';

/**
 * Maps the given list of items (typically component props) into an array of ThemeContextProps
 * which are used to build the Theme context items as well as provide the onChangeTheme callback.
 */
function themeContextsFromThemes(
  themes: ThemeProps[],
  /** A callback if a child component wants to override the them at run-time, it receives theme and index of item. */
  onChangeIndexedTheme: (theme: ThemeProps, index: number) => void
): ThemeContextProps[] {
  return themes.map<ThemeContextProps>((theme, index) => ({
    theme: new CurrentTheme(theme, themes[index - 1]),
    changeTheme: (newTheme: ThemeProps) => onChangeIndexedTheme(newTheme, index),
  }));
}

/**
 * Updates the theme of the given item in the array, which updates the state
 * to re-render everything with the new overridden theme
 */
function updateThemeContext(
  theme: ThemeProps,
  index: number,
  setThemeContexts: React.Dispatch<React.SetStateAction<ThemeContextProps[]>>
) {
  setThemeContexts((themeContexts) => {
    // add all themeContexts before current index
    const arr = themeContexts.slice(0, index);

    // add new themeContext for current index
    arr.push({
      ...themeContexts[index],
      theme: new CurrentTheme(theme, index > 0 ? themeContexts[index - 1].theme : null),
    });

    if (index < themeContexts.length - 1) {
      // add new themeContext for next index (which includes pointer to current)
      arr.push({
        ...themeContexts[index + 1],
        theme: new CurrentTheme(themeContexts[index + 1].theme, theme),
      });

      // add rest of themeContexts
      arr.push(...themeContexts.slice(index + 2, themeContexts.length));
    }

    return arr;
  });
}

/**
 * Creates an array of ThemeContexts which we provide to each child using a ThemeContext.Provider.
 * The context object includes both the theme, a boolean specifying whether the previous
 * theme was the same, and also a function to override the theme for the current child.
 */
export default function useThemedComponentsState(themes: ThemeProps[]) {
  const [themeContexts, setThemeContexts] = useState(
    themeContextsFromThemes(
      themes,
      function onChangeIndexedTheme(newTheme: ThemeProps, index: number) {
        updateThemeContext(newTheme || themes[index], index, setThemeContexts);
      }
    )
  );

  // if the item props change for any reason, we need to update the context and re-render
  useUpdateEffect(() => {
    setThemeContexts(
      themeContextsFromThemes(
        themes,
        function onChangeIndexedTheme(newTheme: ThemeProps, index: number) {
          updateThemeContext(newTheme || themes[index], index, setThemeContexts);
        }
      )
    );
  }, [themes]);

  return themeContexts;
}
