import { equalColor } from '@nrdy-marketing-engine/equal-color';
import { ThemeProps } from '../@types/theme-props';
import nspColorAsString from './nsp-color-as-string';

/**
 * Returns true if the two themes have the same background color (or are both null).
 */
function equalThemeBackground(themeA?: ThemeProps, themeB?: ThemeProps): boolean {
  if (themeA === undefined || themeB === undefined) return false;
  return equalColor(
    nspColorAsString(themeA?.backgroundColor),
    nspColorAsString(themeB?.backgroundColor)
  );
}

export default equalThemeBackground;
