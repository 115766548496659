'use client';

import clsx from 'clsx';
import { Children, ReactElement, useMemo } from 'react';
import { ThemeProps } from '../@types/theme-props';
import ThemeContext from './theme-context';
import useThemedComponentsState from './use-themed-components-state';

type ThemedComponent = ReactElement<{ sticky: Boolean; theme: ThemeProps }>;

/**
 * Receives a set of children and provides each one with a ThemeContext so that ancestors
 * can `useThemeContext()` and access dynamic theme information. It also understands when
 * two children have the same theme background, and provides `sameBackgroundAsPrevious` to
 * the ThemeContext.
 *
 *     function ChildComponent({} : { theme: ThemeProps }) {
 *       const { sameBackgroundAsPrevious } = useThemeContext();
 *       return (
 *         <div className={sameBackgroundAsPrevious ? 'pb-24' ? 'py-24'}>
 *           I know my theme!
 *         </div>
 *       )
 *     }
 *
 *     return (
 *       <ThemedComponents>
 *         <ChildComponent theme={{ backgroundColor: '#f00' }} />
 *         <ChildComponent theme={{ backgroundColor: '#ff0' }} />
 *       </ThemedComponents>
 *     );
 */
function ThemedComponents({ children }: { children: ThemedComponent | ThemedComponent[] }) {
  const themes: ThemeProps[] = useMemo(
    () => Children.map(children, (child) => child.props.theme || {}),
    [children]
  );

  const themeContexts = useThemedComponentsState(themes);

  return (
    <>
      {Children.map(children, (child, index) => {
        const className = clsx({
          'bg-background': themeContexts[index]?.theme?.backgroundColor,
          'sticky top-[--header-height] z-10': child?.props?.sticky,
          'text-text': themeContexts[index]?.theme?.textColor,
          'transition-colors': true,
        });

        return (
          <ThemeContext.Provider key={child.key} value={themeContexts[index]}>
            <div className={className} style={themeContexts[index]?.theme.themeStyles}>
              {child}
            </div>
          </ThemeContext.Provider>
        );
      })}
    </>
  );
}

export default ThemedComponents;
